import { IBank } from '@admin/models/IBank';
import { BanksService } from '@admin/services/banks.service';
import { CurrencyService } from '@admin/services/currency.service';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ChangeDetectorRef  } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Business } from '@support/models/business.model';
import { Collection } from '@support/models/collection.model';
import { ApprovalPaymentsService } from '@support/services/approvalPayments.service';
import { NotificationsService } from '@tools/services/notifications.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-approve-charges',
  templateUrl: './approve-charges.component.html',
  styleUrls: ['./approve-charges.component.scss'],
})
export class ApproveChargesComponent implements OnInit {

  public loading: boolean = false;
  public form: FormGroup;
  public business: Business;
  public collection: Collection
  public showCheckInfo: boolean = false;
  public showRateInput: boolean = false;
  public banks: IBank[]=[];
  public disabled: boolean = true;
  public paymentDate: any;
  public currencys: any;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private approvalPaymentsService: ApprovalPaymentsService,
    private _snackBar: NotificationsService,
    private _bank: BanksService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private _currency:CurrencyService,
    private datePipe: DatePipe
  ) {
  }

  ngOnInit(): void {
    this.init()
  }

  async init(): Promise<void> {

    this.collection = this.config?.data?.collection
    this.business = this.config?.data?.business

    await this.createApprovalPaymentForm();
    await this.subscribeToFormChanges();

    await this.getBanks();
    await this.getCurrencys();

    this.approvalPaymentsService.get(this.collection.id).subscribe(
      (res:any)=> {
        this.form.patchValue({receipt_number: res.receipt_number});

        if (this.collection?.payment_method === 'Cheque') {
          this.showCheckInfo = true;
        } else {
          this.showCheckInfo = false;
        }
        this.loading = false;
      },
      (err:any)=>{
        this._snackBar.openSnackBar(err?.error ? err?.error?.error : 'Error al generar numero de recibo', 'OK');
        this.loading = false;
      }
    )
    this.cdr.detectChanges();
  }

  createApprovalPaymentForm(): void {
    this.form = this.fb.group({
      receipt_number: ['', Validators.required],
      transaction_date: ['', Validators.required],
      transaction_number: ['', Validators.required],
      received_amount: new FormControl(0,[Validators.required, Validators.min(0.1)]),
      currency_id: ['', Validators.required],
      rate: new FormControl(1,[Validators.min(0.1)]),
      total_amount: new FormControl( 0, [Validators.required, Validators.min(0.1) ,Validators.max(this.collection.amount)]),
      origin_bank_id: [''],
      check_number: [''],
    });
  }

  getErrorMessage (controlName: string): string {

    const field = this.form.get(controlName);

    if(!field || !field.invalid) return ''

    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }

    if (field.hasError('max')) {
      return 'El valor actual excede el valor máximo permitido';
    }

    if (field.hasError('min')) {
      return 'El campo debe contener un valor mayor a 0.1';
    }

    return 'Debe ingresar un valor válido'
  }

  fiedIsInvalid(controlName: string) {
    const field = this.form.get(controlName);
    return (
      field &&
      field.invalid &&
      (field.touched)
    );
  }

  subscribeToFormChanges(): void {
    this.form.get('received_amount').valueChanges.subscribe(() => {
      this.calculateTotalAmount();
    });

    this.form.get('rate').valueChanges.subscribe(() => {
      this.calculateTotalAmount();
    });

    this.form.get('currency_id').valueChanges.subscribe(() => {
      this.calculateTotalAmount();
      const currencyId = this.business?.project?.currency_id;
      const selectedCurrencyId = this.form.get('currency_id').value;
      this.showRateInput = selectedCurrencyId !== currencyId;
    });
  }

  calculateTotalAmount(): void {
    const receivedAmount : number = this.form.get('received_amount').value;
    const rate: number = this.form.get('rate').value;
    const currencyId = this.form.get('currency_id').value;

    let totalAmount: number = 0;

    if (this.showRateInput && rate) {
      if (this.business?.project?.currency_id === 1) {
        totalAmount = receivedAmount / rate;
      } else {
        totalAmount = receivedAmount * rate;
      }
    } else {
      totalAmount = receivedAmount;
    }

    // Redondear el monto total a dos decimales
    totalAmount = parseFloat(totalAmount.toFixed(2));

    this.form.get('total_amount').setValue(totalAmount);
  }

  approvePayment() {
    if (!this.form.valid) {
      for (const controlName in this.form.controls) {
        if (this.form.controls.hasOwnProperty(controlName)) {
          const control = this.form.get(controlName);
          control.markAsTouched(); // Marcar el control como tocado para mostrar errores
        }
      }
      this._snackBar.openSnackBar('Por favor complete el formulario', 'OK');
      return;
    }

    this.loading = true;

    const formattedTransactionDate = this.datePipe.transform(this.form.get('transaction_date').value, 'yyyy-MM-dd');
    const data = {
      ...this.form.getRawValue(),
      collection_id: this.collection?.id,
      transaction_date: formattedTransactionDate
    };

    this.approvalPaymentsService.create(data).subscribe(
      async(res: any) => {
        this._snackBar.openSnackBar('Cobro conciliado con exito', 'OK');
        this.closeDialog(true)
      },
      (err: HttpErrorResponse) => {
        if (err.error) {
          console.log(err);
          this._snackBar.openSnackBar(err.error.error, 'OK');
        } else {
          this._snackBar.openSnackBar('Ocurrio un error al conciliar el cobro', 'OK');
        }
      }
    )
  }


  getBanks(): void {
    this._bank.get(1).subscribe({
      next: (response: any) => {
        if (response && response.data) {
          this.banks = response.data;
        }
      },
      error: (error: HttpErrorResponse) => {
        this._snackBar.openSnackBar('Ha ocurrido un error al consultar el listado de bancos', 'OK');
      }
    });
  }

  getCurrencys():void{
    this._currency.get().subscribe(
      (res:any)=>{
        if (res && res.data) {
          this.currencys = res.data
        }
      },
      (err:any)=>{
        this._snackBar.openSnackBar('Error al consultar companies promotoras', 'OK');
        this.loading = false;
      }
    )
  }

  changeCurrency(event: any): void {
    const id = event.value;
    const currencyId = this.business?.project?.currency_id;

    if (id !== currencyId) {
      this.showRateInput = true;
    } else {
      this.showRateInput = false;
    }
  }

  closeDialog(success: boolean = false): void {
    this.ref.close(success);
  }

}
