<p-dialog header="Registrar cobro" [(visible)]="visible" [modal]="true" [style]="{width: '65vw'}"
[draggable]="false" [resizable]="false" (onHide)="hideModal()">
  <ng-template pTemplate="content">
    <app-spinner *ngIf="loading"></app-spinner>
    <form [formGroup]="form" *ngIf="!loading && form">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-12 mt-3">
            <span class="p-float-label">
              <input
                pInputText
                required
                id="concept"
                class="w-full"
                placeholder="Concepto"
                aria-describedby="concept-help"
                formControlName="concept"
                [ngClass]="{
                  'ng-invalid ng-dirty': fiedIsInvalid('concept')
                }"
              />
              <label for="name">Concepto *</label>
            </span>
            <!-- <small id="name-help" class="text-danger small">{{
              getError("concept")
            }}</small> -->
          </div>
          <div class="col-lg-4 col-12 mt-3">
            <span class="p-float-label">
              <input
                id="transaction_number"
                formControlName="transaction_number"
                type="number"
                pInputText
                class="w-100"
                [ngClass]="{'ng-invalid ng-dirty': fiedIsInvalid('transaction_number') }">
              <label for="concept">Número de transacción *</label>
            </span>
            <!-- <small class="p-error block" *ngIf="fiedIsInvalid('transaction_number')">{{ getErrorMessage('transaction_number') }}</small> -->
          </div>
          <div class="col-lg-4 col-12 mt-3">
            <span class="p-float-label">
              <p-dropdown
              inputId="bank_account"
              appendTo="body"
              [options]="bankAccounts"
              formControlName="bank_account_id"
              optionLabel="name_account"
              optionValue="id"
              placeholder="Seleccione una cuenta"
              styleClass="w-100"
              [ngClass]="{'ng-invalid ng-dirty': fiedIsInvalid('bank_account_id') }"
              (onChange)="setBank($event)"
              >
                <ng-template let-account pTemplate="item">
                  <span>{{account.name_account}}</span><br/>
                  <small>{{account.number_account}}</small>
                </ng-template>
              </p-dropdown>
              <label for="bank_account">Cuenta bancaria *</label>
            </span>
            <!-- <small class="p-error block" *ngIf="fiedIsInvalid('bank_account_id')"> {{ getErrorMessage('bank_account_id') }} </small> -->
          </div>
        </div>
        <div class="row mt-3">
          <div *ngIf="showRateInput" class="col-lg-4 col-12 mt-3">
            <span class="p-float-label">
              <p-inputNumber
                id="convertion_rate"
                formControlName="convertion_rate"
                pInputNumber
                mode="decimal"
                locale="en-US"
                [minFractionDigits]="2"
                styleClass="w-100"
                class="w-100"
                [inputStyle]="{'text-align': 'right'}"
                [min]="0"
                [ngClass]="{'ng-invalid ng-dirty': fiedIsInvalid('convertion_rate') }"
                >
              </p-inputNumber>
              <label for="convertion_rate">Tasa de conversión *</label>
            </span>
            <small class="p-error block" *ngIf="fiedIsInvalid('convertion_rate')">{{getErrorMessage('convertion_rate')}}</small>
          </div>
          <div class="col-12 mt-3" [ngClass]="showRateInput ? 'col-lg-4' : 'col-lg-6'">
            <span class="p-float-label">
              <p-inputNumber
                id="transaction_amount"
                formControlName="transaction_amount"
                pInputNumber
                mode="decimal"
                locale="en-US"
                [minFractionDigits]="2"
                styleClass="w-100"
                class="w-100"
                [inputStyle]="{'text-align': 'right'}"
                [min]="0"
                [ngClass]="{'ng-invalid ng-dirty': fiedIsInvalid('transaction_amount') }"
                >
              </p-inputNumber>
              <label for="transaction_amount">Monto de transacción *</label>
            </span>
            <small class="p-error block" *ngIf="fiedIsInvalid('transaction_amount')">{{getErrorMessage('transaction_amount')}}</small>
          </div>
          <div class="col-12 mt-3" [ngClass]="showRateInput ? 'col-lg-4' : 'col-lg-6'">
            <span class="p-float-label">
              <p-inputNumber
                id="amount"
                formControlName="amount"
                pInputNumber
                mode="decimal"
                locale="en-US"
                [minFractionDigits]="2"
                styleClass="w-100"
                class="w-100"
                [inputStyle]="{'text-align': 'right'}"
                [min]="0"
                [ngClass]="{'ng-invalid ng-dirty': fiedIsInvalid('amount') }">
              </p-inputNumber>
              <label for="amount">Monto Total *</label>
            </span>
            <small class="p-error block" *ngIf="fiedIsInvalid('amount')">{{getErrorMessage('amount')}}</small>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12 col-lg-4 mt-3">
            <span class="p-float-label">
              <p-dropdown
              inputId="payment_method"
              appendTo="body"
              [options]="[
                {value:'Efectivo', label: 'Efectivo'},
                {value:'Transferencia', label: 'Transferencia'},
                {value:'Depósito', label: 'Depósito'},
                {value:'Cheque', label: 'Cheque'},
              ]"
              formControlName="payment_method"
              optionLabel="label"
              optionValue="value"
              placeholder="Seleccione un método"
              styleClass="w-100"
              [ngClass]="{'ng-invalid ng-dirty': fiedIsInvalid('payment_method') }"
              >
              </p-dropdown>
              <label for="payment_method">Método de pago *</label>
            </span>
            <small class="p-error block" *ngIf="fiedIsInvalid('payment_method')">{{ getErrorMessage('payment_method') }}</small>
          </div>
          <div class="col-12 col-lg-4 mt-3">
            <span class="p-float-label">
              <p-calendar
                inputId="transaction_date"
                appendTo="body"
                formControlName="transaction_date"
                styleClass="w-100"
                class="w-100"
                [ngClass]="{'ng-invalid ng-dirty': fiedIsInvalid('transaction_date') }">
              </p-calendar>
              <label for="transaction_date">Fecha de transacción *</label>
            </span>
            <small class="p-error block" *ngIf="fiedIsInvalid('transaction_date')">{{getErrorMessage('transaction_date')}}</small>
          </div>
          <div class="col-12 col-lg-4 mt-3">
            <p-fileUpload
              id="payment_receipt"
              styleClass="payment-receipt_box"
              [showUploadButton]="false"
              [showCancelButton]="false"
              chooseLabel="Subir comprobante"
              chooseIcon="pi pi-upload"
              accept="image/*,application/pdf"
              (onSelect)="onSelectFile($event)"
              (onClear)="onRemoveFile()"
              (onRemove)="onRemoveFile()">
            </p-fileUpload>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12 col-lg-6 mt-3">
            <span class="p-float-label">
              <p-dropdown
                inputId="collection_type_id"
                appendTo="body"
                [options]="collectionTypes"
                formControlName="collection_type_id"
                optionValue="id"
                optionLabel="name"
                styleClass="w-100"
                [ngClass]="{'ng-invalid ng-dirty': fiedIsInvalid('collection_type_id') }"
                [disabled]="form.controls['collection_type_id'].disabled"
              >
              </p-dropdown>
              <label for="collection_type_id">Tipo de pago *</label>
            </span>
            <small class="p-error block" *ngIf="fiedIsInvalid('collection_type_id')">{{getErrorMessage('collection_type_id')}}</small>
          </div>
          <div class="col-12 col-lg-6 mt-3">
            <span class="p-float-label">
              <p-dropdown
                inputId="collection_status_id"
                appendTo="body"
                [options]="collectionStatus"
                formControlName="collection_status_id"
                optionValue="id"
                optionLabel="name"
                styleClass="w-100"
                [ngClass]="{'ng-invalid ng-dirty': fiedIsInvalid('collection_status_id') }"
                [disabled]="form.controls['collection_status_id'].disabled"
                >
              </p-dropdown>
              <label for="collection_status_id">Estado *</label>
            </span>
            <small class="p-error block" *ngIf="fiedIsInvalid('collection_status_id')">{{ getErrorMessage('collection_status_id') }}</small>
          </div>
        </div>
      </div>
    </form>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="d-flex justify-content-end">
      <button pButton type="submit" (click)="register()" class="p-button-raised p-button-sm p-button-secondary mr-1">
        <span *ngIf="!isEditing">Registrar</span>
        <span *ngIf="isEditing">Actualizar</span>
      </button>

      <button (click)="hideModal()" pButton
        class="p-button-raised p-button-sm">
        Cancelar
      </button>
    </div>
  </ng-template>
</p-dialog>
