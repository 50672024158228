import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, Inject } from '@angular/core';
import { CollectionService } from '@support/services/collection.service';
import { DialogService } from 'primeng/dynamicdialog';
import { ApproveChargesComponent } from '../approve-charges/approve-charges.component';
import { CancelChargesComponent } from '../cancel-charges/cancel-charges.component';
import { RejectChargesComponent } from '../reject-charges/reject-charges.component';
import { Collection } from '@support/models/collection.model';
import { saveAs } from 'file-saver';
import { MessageService } from 'primeng/api'
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-list-by-business',
  templateUrl: './list-by-business.component.html',
  styleUrls: ['./list-by-business.component.scss'],
  providers: [MessageService, DialogService]
})
export class ListByBusinessComponent implements OnInit, OnChanges {

  @Input() collections: any;
  @Input() canEdit: any;
  @Input() canCreate: any;
  @Input() user: any;
  @Input() business: any;

  @Output() complete = new EventEmitter<boolean>();

  public cols: any[] = [];
  public globalFilterFields: string[] = [];
  public isLoading = false;

  constructor(
    private _collections:CollectionService,
    private dialogService: DialogService,
    private messageService: MessageService,
    @Inject(HttpClient) private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.setColumns();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['collections']) {
      this.setColumns();
    }
  }

  setColumns(): void {
    this.collections.forEach(element => {
      element.bankName = element.bank.name;
      element.account = element.bank_account.number_account;
      element.type = element.collections_type.name;
      element.status = element.collection_status.name;
    });
    this.cols = [
      { field: 'amount', header: 'Monto', dataKey: 'amount' },
      { field: 'transaction_date', header: 'Fecha de transaccion', dataKey: 'transaction_date' },
      { field: 'bankName', header: 'Banco', dataKey: 'bankName' },
      { field: 'account', header: 'Cuenta Bancaria', dataKey: 'account' },
      { field: 'type', header: 'Tipo de pago', dataKey: 'type' },
      { field: 'status', header: 'Estado', dataKey: 'status' }
    ];
    this.globalFilterFields = this.cols.filter(col => col.dataKey).map(col => col.dataKey);
  }

  getFileNameFromUrl(url: string): string {
    if (url) {
      const parts = url.split('/');
      const fileName = parts[parts.length - 1];
      return decodeURIComponent(fileName);
    }
    return '';
  }

  async downloadFile(fileUrl: string) {
    // if (!this.isMobileDevice()) {
    //   const headers = new HttpHeaders({ 'Accept': 'image/jpeg, image/png' });
    //   this.http.get(fileUrl, { headers: headers, responseType: 'blob' })
    //   .subscribe(response => {
    //     const blob = response;
    //     const file = new File([blob], this.getFileNameFromUrl(fileUrl));
    //     saveAs(file, this.getFileNameFromUrl(fileUrl));
    //   });
    //   this.clear();
    //   this.showInfo({
    //     sticky: true,
    //     severity: 'success',
    //     summary: 'Comprobante de pago generado',
    //     detail: 'El comprobante de pago se ha generado correctamente'
    //   });
    // } else {
      let a: any = document.createElement('a');
      a.download = true;
      a.target = '_blank';
      a.href= fileUrl;
      a.click();
    // }
  }

  isMobileDevice(): boolean {
    // Implementa la lógica para detectar si el usuario está accediendo desde un dispositivo móvil
    // Puedes utilizar una biblioteca como 'mobile-detect.js' o implementar tu propia lógica
    // Ejemplo:
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile|iphone|ipad|android/.test(userAgent);
  }

  generatePaymentReceipt(id: any): void {
    this._collections.getPdfVoucher(id).subscribe((response: Blob) => {
      if (this.isMobileDevice()) {
        // Descargar el archivo PDF en dispositivos móviles
        saveAs(response);
        this.clear();
        this.showInfo({
          sticky: true,
          severity: 'success',
          summary: 'Recibo generado',
          detail: 'El recibo se ha generado correctamente'
        });
      } else {
        const file = new Blob([response], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        this.clear();
        this.showInfo({ sticky: true, severity: 'success', summary: 'Recibo generado', detail: 'El recibo se ha generado correctamente' });
      }
    });
  }


  openApprovalPaymentDialog(collectionId: number) {
    console.log('conciliar 2');
    const collection = this.collections.find((item: Collection) => item.id === collectionId);
    const approvalDialog = this.dialogService.open(ApproveChargesComponent, {
      data: {
        collection,
        business: this.business
      },
      header: 'Conciliación de cobro',
      width: '50vw',
      height: '80vh',
      maximizable: true,
    });

    approvalDialog.onClose.subscribe((success: boolean | null) => {
      if (success) {
        this.complete.emit(true);
      }
    });
  }

  openRejectionPaymentDialog(collectionId: number) {
    const collection = this.collections.find((item: Collection) => item.id === collectionId);
    const rejectDialog = this.dialogService.open(RejectChargesComponent, {
      data: {
        collection
      },
      header: 'Rechazo de cobro',
      width: '50vw',
      maximizable: true,
    });

    rejectDialog.onClose.subscribe((success: boolean | null) => {
      if (success) {
        this.complete.emit(true);
      }
    });
  }

  openCancelPaymentDialog(id: number) {
    console.log(id);
    const collection = this.collections.find((item: Collection) => item.id === id);
    this.isLoading = true;
    const cancelDialog = this.dialogService.open(CancelChargesComponent, {
      data: {
        collection
      },
      header: 'Anular cobro',
      width: '50vw',
      maximizable: true,
    });
    cancelDialog.onClose.subscribe((success: boolean | null) => {
      if (success) {
        this.complete.emit(true);
      }
    });
    this.isLoading = false;
  }

  showInfo({ sticky, severity, summary, detail }) {
    this.messageService.add({ sticky, severity, summary, detail });
  }

  clear() {
    this.messageService.clear();
  }

}
