<p-dialog [header]="'Detalle de nota'" [(visible)]="visible" [modal]="true" [style]="{ width: '50vw' }"
  [draggable]="false" [resizable]="false" (onHide)="hideModal()">
  <ng-template pTemplate="content">
    <div>

      <p-divider [align]="'center'" class="mx-auto flex w-full justify-center my-3 px-3">
        <div class="inline-flex align-items-center bg-[white] px-3 z-1">
          <i class="pi pi-file mr-2"></i>
          <b class="lead"><span style="font-size: 20px !important;">Información General</span></b>
        </div>
        <hr class="absolute my-3 w-[94%]" />
      </p-divider>

      <div class="row">

        <div class="col-12 col-md-12 my-1">
          <div class="flex flex flex-column gap-2 pt-3">
            <span class="">
              <label class="mr-1">Fecha:</label>
              <span>{{convertDate(selectedNote?.created_at)}}</span>
            </span>
          </div>
        </div>

        <div class="col-12 col-md-12 my-1">
          <div class="flex flex flex-column gap-2 pt-3">
            <span class="">
              <label class="mr-1">Título:</label>
              <span>{{selectedNote?.title}}</span>
            </span>
          </div>
        </div>

        <div class="col-12 col-md-12 my-1">
          <div class="flex flex flex-column gap-2 pt-3">
            <span class="">
              <label class="mr-1">Descripción:</label>
              <span>{{selectedNote?.description}}</span>
            </span>
          </div>
        </div>

        <div class="col-12 col-md-12 my-1" *ngIf="selectedNote && selectedNote.document">
          <div class="flex flex flex-column gap-2 pt-3">
            <label class="mr-1">Documento:</label>
            <span class="flex flex flex-row gap-2">
              <span class="flex items-center">{{getFileNameFromUrl(selectedNote?.document?.url)}}</span>
              <span>
                <button
                  [disabled]="false"
                  pButton
                  type="button"
                  class="p-button-rounded p-button-text p-button-info"
                  icon="pi pi-download"
                  pTooltip="Descargar"
                  (click)="downloadDocument(selectedNote?.document?.url)"
                ></button>
              </span>
            </span>
          </div>
        </div>


      </div>

    </div>
  </ng-template>
</p-dialog>