import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  public apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient
  ) { }

  get(status?:number): Observable<any> {
    let url = `${this.apiUrl}project/list?`;
    if(status) url +=`status=${status}`

    return this.http.get(url);
  }

  getWithCollected(subscription_id?:number): Observable<any> {
    let url = `${this.apiUrl}projects/with-collected?`;
    if(subscription_id) url +=`subscription_id=${subscription_id}`

    return this.http.get(url);
  }

  getStatus(): Observable<any> {
    let url = `${this.apiUrl}projects/status/list?`;
    return this.http.get(url);
  }

  create(body:FormData): Observable<any> {
    let url = `${this.apiUrl}project/create`;
    return this.http.post(url, body);
  }

  update(body:FormData, projectId:number): Observable<any> {
    let url = `${this.apiUrl}project/update/${projectId}`;
    return this.http.post(url, body);
  }

  detail(projectId:number): Observable<any>{
    const url = `${this.apiUrl}project/${projectId}`
    return this.http.get(url);
  }

  delete(projectId:number): Observable<any> {
    let url = `${this.apiUrl}project/delete/${projectId}`;
    return this.http.delete(url);
  }

  listAdvance(): Observable<any>{
    const url = `${this.apiUrl}advances/list`
    return this.http.get(url);
  }

  listCurrency(): Observable<any>{
    const url = `${this.apiUrl}currency/list`
    return this.http.get(url);
  }

  listBanks(): Observable<any>{
    const url = `${this.apiUrl}banks/list`
    return this.http.get(url);
  }

  updateSales(body:any, projectId:number): Observable<any> {
    const url = `${this.apiUrl}project/update/active-sales/${projectId}`
    return this.http.post(url, body);
  }

  listDocuments(projectId:number): Observable<any>{
    const url = `${this.apiUrl}project/documents/${projectId}`
    return this.http.get(url);
  }
  
  uploadDocument(body:FormData): Observable<any>{
    const url = `${this.apiUrl}project/documents`
    return this.http.post(url, body);
  }
  
  deleteDocument(projectId:number): Observable<any>{
    const url = `${this.apiUrl}project/documents/${projectId}`
    return this.http.delete(url);
  }

}
