<p-card styleClass="activity-chart">
  <ng-template pTemplate="header" styleClass="activity-chart_header">
    <div class="d-flex align-items-center justify-content-between px-2">
      <div>
        <span class="fw-bold">Actividades</span>
      </div>
      <div class="d-flex align-items-center">
        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input type="text" pInputText class="p-inputtext-sm activity-search" placeholder="Buscar" [(ngModel)]="search" (input)="changeSearch($event)"/>
        </span>
        <div *ngIf="items?.length > 0">
          <button pButton pRipple type="button" icon="pi pi-ellipsis-v" class="p-button-rounded p-button-text p-button-primary" (click)="menu.toggle($event)"></button>
          <p-menu #menu [popup]="true" [model]="items"></p-menu>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <p-scrollPanel [style]="{height: '200px'}">
      <div *ngIf="loading" class="custom-skeleton p-4">
        <ul class="m-0 o-0">
            <li *ngFor="let item of [1, 2, 3]" class="mb-3">
                <div class="flex">
                    <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>
                    <div style="flex: 1">
                        <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
                        <p-skeleton width="75%"></p-skeleton>
                    </div>
                </div>
            </li>
        </ul>
      </div>
      <p-confirmPopup key="confirmTaskDelete"></p-confirmPopup>
      <p-confirmPopup key="confirmTaskComplete"></p-confirmPopup>
      <p-timeline *ngIf="!loading && filteredEvents && canRead" [value]="filteredEvents">
        <ng-template pTemplate="marker" let-event>
          <i class="pi pi-circle timeline-icon"></i>
        </ng-template>
        <ng-template pTemplate="content" let-event>
          <div *ngIf="!event?.submodule; else subModule" class="basic-layout">
            <h5 class="text-muted">
              {{event.date}} - {{event.time}}
            </h5>
            <p>{{event.message}}</p>
          </div>
          <ng-template #subModule>
            <div *ngIf="event.submodule === 'document' && event.type === 'upload'">
              <h5 class="text-muted">
                {{event.date}} - {{event.time}}
              </h5>
              <p>{{event.message}}.
                <span>
                  <i>Nombre: </i>
                  <a [href]="event.document?.link" class="text-muted fw-bold" style="text-decoration: none;">{{event?.document?.name}}</a>
                </span>.
                <span *ngIf="event?.document?.type">
                  <i>Tipo: </i>
                  {{event?.document?.type}}
                </span>
              </p>
            </div>
            <div *ngIf="event.submodule === 'document' && event.type === 'delete'" class="basic-layout">
              <h5 class="text-muted">
                {{event.date}} - {{event.time}}
              </h5>
              <p>{{event.message}}.
                <span>
                  <i>Nombre: </i>
                  {{event?.document?.name}}
                </span>.
              </p>
            </div>
            <div *ngIf="(event.submodule === 'note' && event.type === 'create') || event.submodule === 'note' && event.type === 'update'" class="note-layout">
              <div class="note-layout_header">
                <h5 class="text-muted">
                  {{event.date}} - {{event.time}}
                  <i *ngIf="event?.note?.has_document" class="pi pi-paperclip ml-2" style="font-size: 10px;"></i>
                </h5>
                <div>
                  <button *ngIf="canEdit && !event?.note?.deleted" pButton pRipple type="button" [disabled]="event?.note?.deleted" icon="pi pi-pencil" class="p-button-rounded p-button-text p-button-warning" (click)="editNote(event?.note?.id)"></button>
                  <button *ngIf="canDelete && !event?.note?.deleted" pButton pRipple type="button" [disabled]="event?.note?.deleted" icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-text" (click)="deleteNote(event.note.id)"></button>
                </div>
              </div>
              <p class="text-white">Nota {{event?.type === 'create' ? 'creada ' : 'modificada '}} por: {{ event.user.name}}</p>
              <div class="note-layout_title">
                <p class="text-white fw-700">{{event?.note?.title}}</p>
                <button *ngIf="canRead && !event?.note?.deleted" pButton pRipple type="button" label="Ver más" class="p-button-text text-white" (click)="noteDetail(event?.note?.id)" [disabled]="event?.note?.deleted"></button>
              </div>
            </div>
            <div *ngIf="event.submodule === 'note' && event.type === 'delete'" class="basic-layout">
              <h5 class="text-muted">
                {{event.date}} - {{event.time}}
                <i *ngIf="event?.note?.has_document" class="pi pi-paperclip ml-2" style="font-size: 10px;"></i>
              </h5>
              <p>{{event.message}}. Título: {{event?.note?.title}}</p>
            </div>
            <div *ngIf="(event.submodule === 'task' && event.type === 'create') || event.submodule === 'task' && event.type === 'update'" class="task-layout">
              <div class="task-layout_header">
                <h5 class="text-muted">
                  {{event.date}} - {{event.time}}
                </h5>
                <div>
                  <button *ngIf="canEditTask" pButton pRipple type="button" [disabled]="event?.task?.deleted || event?.task?.status !== 'Iniciado'" icon="pi pi-check" class="p-button-rounded p-button-text p-button-success" (click)="taskStatusChange(event?.task, 'Completado')"></button>
                  <button *ngIf="isAdmin" pButton pRipple type="button" [disabled]="event?.task?.deleted" icon="pi pi-user-plus" class="p-button-rounded p-button-secondary p-button-text" (click)="assignmentTask(event?.task?.id)"></button>
                  <button *ngIf="canDeleteTask" pButton pRipple type="button" [disabled]="event?.task?.deleted" icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-text" (click)="deleteTask(event?.task?.id)"></button>
                </div>
              </div>
              <p>Tarea {{event?.type === 'create' ? 'creada ' : 'modificada '}} por: {{ event.user.name}}</p>
              <p>{{event?.task?.title}}</p>
              <div class="task-layout_footer">
                <p-chip [label]="event?.task?.status" [styleClass]="getStatusClass(event?.task?.status, event?.task?.deleted)"></p-chip>
                <button *ngIf="canReadTask" pButton pRipple type="button" label="Ver más" class="p-button-text" (click)="taskDetail(event?.task?.id)" [disabled]="event?.task?.deleted"></button>
              </div>
            </div>
            <div *ngIf="event.submodule === 'task' && event.type === 'delete'" class="basic-layout">
              <h5 class="text-muted">
                {{event.date}} - {{event.time}}
              </h5>
              <p>{{event.message}}. Asunto: {{event?.task?.title}}</p>
            </div>
            <div *ngIf="event.submodule === 'task' && event.type === 'update/status'" class="basic-layout">
              <h5 class="text-muted">
                {{event.date}} - {{event.time}}
              </h5>
              <p>{{event.message}}</p>
            </div>
            <div *ngIf="event.submodule === 'assignment'" class="basic-layout">
              <h5 class="text-muted">
                {{event.date}} - {{event.time}}
              </h5>
              <p>{{event.message}}</p>
            </div>
            <div *ngIf="event.submodule === 'collection'" class="basic-layout">
              <h5 class="text-muted">
                {{event.date}} - {{event.time}}
              </h5>
              <p>{{event.message}}</p>
            </div>
            <div *ngIf="event.submodule === 'status'" class="basic-layout">
              <h5 class="text-muted">
                {{event.date}} - {{event.time}}
              </h5>
              <p>{{event.message}}</p>
            </div>
            <div *ngIf="event.submodule === 'transfer'" class="basic-layout">
              <h5 class="text-muted">
                {{event.date}} - {{event.time}}
              </h5>
              <p>{{event.message}}</p>
            </div>
          </ng-template>
        </ng-template>
      </p-timeline>
      <h4 *ngIf="!loading && !canRead" class="w-100 text-center text-secondary">* Usted no posee permisos para ver el histórico de actividades *</h4>
      <h4 *ngIf="!loading && !filteredEvents && canRead" class="w-100 text-center text-secondary">* Aún no hay registro de actividades para este {{moduleId === 7 ? 'Cliente' : moduleId === 10 ? 'Inmueble' : 'Negocio'}} *</h4>
    </p-scrollPanel>
  </ng-template>
</p-card>
<app-create-note
  *ngIf="showNoteForm"
  [moduleId]="moduleId"
  [objectId]="objectId"
  [noteId]="note?.id"
  [noteData]="note"
  (emitVisible)="hideModal($event)"
  (isSuccess)="getSucess($event)">
</app-create-note>

<app-create-task [visible]="showModalTask" (isShowing)="showModalTask = $event" (error)="showTaskError($event)" (complete)="taskCreationComplete($event)" [selectedTask]="selectedTask" [moduleId]="moduleId"
[objectId]="objectId" [messageService]="messageService"></app-create-task>

<app-detail-task [visible]="showDetailTask" (isShowing)="showDetailTask = $event" (error)="showTaskError($event)" (complete)="taskCreationComplete($event)" [selectedTask]="selectedTask" [moduleId]="moduleId"
[objectId]="objectId" [messageService]="messageService"></app-detail-task>

<app-detail-note [visible]="showDetailNote" (isShowing)="showDetailNote = $event" (error)="showNoteError($event)" [selectedNote]="selectedNote" [moduleId]="moduleId"
[objectId]="objectId"></app-detail-note>

<app-upload-business-document
  *ngIf="moduleId === 11"
  [visible]="showBusinessDoc"
  [business]="business"
  (emitVisible)="showBusinessDoc = $event"
  (isSuccess)="completeUpload($event)"
  (failed)="showFailedMessage($event)"
></app-upload-business-document>
<app-upload-document *ngIf="moduleId === 7 || moduleId === 10" [visible]="showClientDoc" [id]="objectId" [moduleId]="moduleId" (success)="completeUpload($event)" (close)="closeModal($event)" (failed)="showFailedMessage($event)"></app-upload-document>

<app-create-assignment-task
  [visible]="showTaskAssignmentForm"
  (emitVisible)="hideAssignmentModal($event)"
  (isSuccess)="getSucessTask($event)"
  [taskId]="taskId">
</app-create-assignment-task>
