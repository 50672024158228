<div class="container" *ngIf="business">
  <div class="row justify-content-between flexx-wrap mt-3">
    <div class="col-4 col-sm-2 col-md-1">
      <p-avatar icon="pi pi-building" styleClass="mr-2" size="xlarge" [style]="{'background-color':'#C9D9DE', 'color': '#2D3E50'}" shape="circle"></p-avatar>
    </div>
    <div class="col-8 col-sm-2 col-md-11">
      <p-divider align="left">
        <b class="lead"><strong>Proyecto: {{ business?.project?.name_project }}</strong></b>
      </p-divider>
    </div>
  </div>
  <div class="row justify-content-around mt-5">
    <div class="col-12 col-sm-6 col-md-4">
        <p class="lead"><strong>Inmueble</strong> {{business?.property?.name_property}}</p>
        <p class="lead">Habitaciones: {{ business?.property?.bedrooms  }}</p>
        <p class="lead">Baños: {{ business?.property?.bathrooms }}</p>
        <p class="lead">Precio Base: {{ business?.property?.price_base }}</p>
        <p *ngIf="business?.property?.solar_square_meters" class="lead">M2: {{ business?.property?.solar_square_meters}}</p>
    </div>
    <div class="col-12 col-sm-6 col-md-8">
      <p-divider align="right" styleClass="px-0">
        <b class="lead flex">
          <strong>Cliente: {{ business?.client?.name }} {{ business?.client?.last_name }}</strong>
          <p-avatar icon="pi pi-user" styleClass="flex ml-5 mr-0 my-0" [style]="{'background-color':'#FC5A34', 'color': '#FFFFFF'}" shape="circle"></p-avatar>
        </b>
      </p-divider>
    </div>
    <!-- <div class="col-12 col-md-2">
    </div> -->
  </div>
</div>
