
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core'
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { Router } from '@angular/router';
import { StorageService } from '@tools/services/storage.service';
import { AuthService } from '@core/auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {

  public headers = new HttpHeaders();
  public token: string;

  constructor(
    private storageSrv: StorageService,
    private authSrv: AuthService,
    private router: Router,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.token = this.storageSrv.getToken();

    if (this.token) {
      req = req.clone({
        setHeaders: { 
          Authorization: `Bearer ${this.token}`,
          // 'Content-Type':  'application/json',
          // 'Access-Control-Allow-Origin': '*',
          // 'Access-Control-Allow-Methods': '*',
          // 'Access-Control-Allow-Headers': '*'
        },
      });
    }

    return next.handle(req).pipe(
        map(event => {
                if (event instanceof HttpResponse) {
                }
                return event;
        }),
        catchError((err) => {
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                  localStorage.clear();
                }
            }
            return throwError(err);
        })
    );

}
}
