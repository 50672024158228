import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Parking, StatusParking } from '@support/models/parking.model';
import { Project } from '@support/models/project.model';
import { Stage } from '@support/models/stage.model';
import { Bank } from '@support/models/project.model';
import { BanksService } from '@admin/services/banks.service';
import { BankAccountsService } from '@support/services/bankAccounts.service';
import { CollectionTypesService } from '@support/services/collectionTypes.service';
import { CollectionStatusService } from '@support/services/collectionStatus.service';
import { CollectionService } from '@support/services/collection.service';
import { ProjectService } from '@support/services/project.service';

import { NotificationsService } from '@tools/services/notifications.service';
import { EMAIL_REGEXP, NAME_PATTERN } from 'src/app/shared/Enums/regexAuth.enum';
@Component({
  selector: 'app-create-reserves',
  templateUrl: './create-reserves.component.html',
  styleUrls: ['./create-reserves.component.scss']
})
export class CreateReservesComponent implements OnInit {

  public form: FormGroup;
  public loading = false;
  public isLoadingProject = false;
  public isLoadingStage = false;
  public isSubmit = false;
  public parkingId: number;
  public parking: Parking;
  public isEditing = false;
  public stages:Stage[];
  public statusParking: StatusParking[];
  public projects: Project[];
  public project: any;
  public projectIdSelected:number;
  public banks: Bank[]= [];
  public bankAccounts: any = [];
  public collectionTypes: any = [];
  public collectionStatus: any = [];
  public paymentOptions: any = [];
  public paymentReceipt: File;
  public showRateInput: boolean = false;

  constructor(
    private _snackBar: NotificationsService,
    private router:Router,
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<CreateReservesComponent>,
    private _banks:BanksService,
    private _bankAccounts:BankAccountsService,
    private _collectionTypes:CollectionTypesService,
    private _collectionStatus:CollectionStatusService,
    private _collection:CollectionService,
    private _project:ProjectService,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }

  ngOnInit(): void {
    this.init();
  }

  async init():Promise<void>{
    await this.getCollectionTypes();
    await this.getCollectionStatus();
    await this.getCollections();
    // await this.getBanks();
    await this.getBankAccounts();
    await this.getProjects();
    await this.getProject();
    await this.createForm();
    await this.subscribeToFormChanges();
    console.log(this.data);

    this.paymentOptions = ['Efectivo', 'Transferencia', 'Deposito', 'Cheque'];

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.parkingId = Number(params.get('id'));
      if (this.parkingId) {
        this.isEditing = true;
        this.getReserves();
      }
    });
  }

  createForm(): void {
    this.form = new FormGroup({
      concept: new FormControl('', Validators.required),
      bank_account_id: new FormControl('', Validators.required),
      transaction_number: new FormControl('', Validators.required),
      payment_method: new FormControl('', Validators.required),
      transaction_amount: new FormControl(0,[Validators.required, Validators.min(0.1)]),
      convertion_rate: new FormControl(1,[Validators.min(0.1)]),
      amount: new FormControl( 0, [Validators.required, Validators.min(0.1)]),
      transaction_date: new FormControl('', Validators.required),
      payment_receipt: new FormControl('', Validators.required),
      collection_type_id: new FormControl('', Validators.required),
      collection_status_id: new FormControl('', Validators.required),
    });
    this.form.controls['collection_status_id'].disable();
    this.form.controls['amount'].disable();
  }

  getError(controlName: string) {
    const control = this.form.get(controlName);
    if (control && control.touched) {
      if (control.hasError('required')) {
        return 'Este campo es requerido';
      }
      if (control.hasError('pattern')) {
        return 'Debe indicar un valor válido';
      }
    }
    return '';
  }

  getBanks():void{
    this._banks.get().subscribe(
      (res:any)=>{
        console.log(res);
        if (res && res.data) {
          this.banks = res.data
        }
      },
      (err:any)=>{
        this._snackBar.openSnackBar('Error en la consulta', 'OK');
        // this.isLoading = false;
      }
    )
  }

  getCollectionTypes():void{
    this._collectionTypes.get().subscribe(
      (res:any)=>{
        console.log(res);
        if (res && res.data) {
          this.collectionTypes = res.data
        }
      },
      (err:any)=>{
        this._snackBar.openSnackBar('Error en la consulta', 'OK');
        // this.isLoading = false;
      }
    )
  }

  getCollectionStatus():void{
    this._collectionStatus.get().subscribe(
      (res:any)=>{
        if (res && res.data) {
          this.collectionStatus = res.data;
          this.form.get('collection_status_id')?.setValue(this.collectionStatus[0]?.id);
        }
      },
      (err:any)=>{
        this._snackBar.openSnackBar('Error en la consulta', 'OK');
      }
    )
  }

  getBankAccounts():void{
    this._bankAccounts.get().subscribe(
      (res:any)=>{
        if (res && res.data) {
          console.log(res.data);
          this.bankAccounts = res.data.filter((el: any) => el.project?.id === this.data?.projectId);
          this.loading = false;
        }
      },
      (err:any)=>{
        this._snackBar.openSnackBar('Error en la consulta', 'OK');
        this.loading = false;
      }
    )
  }

  getReserves(): void {

  }

  completeForm(): void {
    this.form.patchValue({
      description: this.parking.name,
      ammount: this.parking.price,
    });
  }

  onValidator(fieldTag: string, validatorTag: string) {
    const field = this.form.controls[fieldTag];
    return (
      field?.errors &&
      field.errors[validatorTag] &&
      (this.isSubmit || field.touched)
    );
  }


  getProjects():void{
    this.isLoadingProject = true;

  }

  getProject(): void{
    this._project.detail(this.data.projectId).subscribe(
      (response: any) => {
        if (response && response.data) {
          this.project = response.data;
          // if (this.project) this.completeForm();
        }
      },
      (error: HttpErrorResponse) => {
        this._snackBar.openSnackBar('Ha ocurrido un error al consultar el proyecto', 'OK');
      }
    );
  }

  register(): void {
    const bankAccountId = this.form.get('bank_account_id').value;
    let bank: any;
    if (bankAccountId) {
      bank = this.bankAccounts.find((el)=> el.id === bankAccountId).bank;
    }
    console.log(bank);
    console.log(this.form.value)
    if (this.form.invalid) {
      this._snackBar.openSnackBar('Por favor, complete los datos del formulario.', 'OK');
      return;
    }


    let formData = new FormData();

    formData.append('concept', this.form.get('concept').value);
    formData.append('bank_id', bank?.id);
    formData.append('bank_account_id', this.form.get('bank_account_id').value);
    formData.append('transaction_number', this.form.get('transaction_number').value);
    formData.append('transaction_amount', this.form.get('transaction_amount').value);
    formData.append('convertion_rate', this.form.get('convertion_rate').value || 1);
    formData.append('amount', this.form.get('amount').value);
    formData.append('payment_method', this.form.get('payment_method').value);
    formData.append('transaction_date', this.form.get('transaction_date').value);
    formData.append('payment_receipt', this.paymentReceipt);
    formData.append('collection_type_id', this.form.get('collection_type_id').value);
    formData.append('collection_status_id', this.form.get('collection_status_id').value);
    formData.append('business_id', this.data.businessId);
    formData.append('client_id', this.data.clientId);

    formData.forEach((value, key) => {
      console.log(key, value);
    });

    this.loading = true;

    const url =this._collection.create(formData);

  url.subscribe(
    (res: any) => {
      this.loading = false;
      console.log(res, 'creacion');
      const message = this.isEditing ? 'Cobro de reserva modificado exitosamente.' : 'Cobro de reserva registrado exitosamente.'
      this._snackBar.openSnackBar(message, 'OK');
      setTimeout(() => {
        this.close();
      }, 2000);
    },
    (err: HttpErrorResponse) => {
      console.log(err);
      this.loading = false;
      if (!err.ok && err.error) {
        this._snackBar.openSnackBar(err.error.message, 'OK');
      } else {
        this._snackBar.openSnackBar('Error. Por favor intente más tarde.', 'OK');
      }
      setTimeout(() => {
        this.close();
      }, 2000);
    }
  );

  }


  close(): void {
    this.dialogRef.close();
  }

  onFileSelected($event) {
    console.log($event.target.files[0]);
    this.paymentReceipt = $event.target.files[0];
  }

  async getCollections(): Promise<void> {
    this.loading = true;

    this._collection.byBusiness(this.data.businessId).subscribe(
      async (res: any) => {
        console.log(res);
        if (res && res.data) {
          const collections = res.data;
          if (collections.length === 0) {
             await this.setDefaultValues();
            this.form.controls['collection_type_id'].disable();
            this.form.controls['collection_status_id'].disable();
          }
        }
      },
      (err: any) => {
        this._snackBar.openSnackBar('Error al consultar cobros', 'OK');
      },
      () => {
        this.loading = false; // Mover el cambio de loading a la función de completado del observable
      }
    );
  }

  setDefaultValues(): void {
    if(this.collectionStatus.length > 0 && this.collectionTypes.length > 0){
      this.form.get('collection_type_id')?.setValue(this.collectionTypes[0]?.id);
      this.form.get('collection_status_id')?.setValue(this.collectionStatus[0]?.id);
    }

  }


  convertirNumeroALetras(numero: number): string {
    const unidades = ['', 'uno', 'dos', 'tres', 'cuatro', 'cinco', 'seis', 'siete', 'ocho', 'nueve'];
    const decenas = ['diez', 'once', 'doce', 'trece', 'catorce', 'quince', 'dieciséis', 'diecisiete', 'dieciocho', 'diecinueve'];
    const decenas2 = ['', '', 'veinte', 'treinta', 'cuarenta', 'cincuenta', 'sesenta', 'setenta', 'ochenta', 'noventa'];
    const centenas = ['cien', 'ciento', 'doscientos', 'trescientos', 'cuatrocientos', 'quinientos', 'seiscientos', 'setecientos', 'ochocientos', 'novecientos'];

    let entero = Math.floor(numero);
    let decimal = Math.round((numero - entero) * 100);

    let enteroEnLetras = '';
    let decimalEnLetras = '';

    if (entero > 0) {
         enteroEnLetras = this.convertirNumeroALetrasEntero(entero);
    }

    if (decimal > 0) {
        decimalEnLetras = `con ${decimal} centavos`;
    }

    return `${enteroEnLetras} ${decimalEnLetras}`.trim();
}

  convertirNumeroALetrasEntero(numero: number): string {
    const unidades = ['', 'uno', 'dos', 'tres', 'cuatro', 'cinco', 'seis', 'siete', 'ocho', 'nueve'];
    const decenas = ['diez', 'once', 'doce', 'trece', 'catorce', 'quince', 'dieciséis', 'diecisiete', 'dieciocho', 'diecinueve'];
    const decenas2 = ['', '', 'veinte', 'treinta', 'cuarenta', 'cincuenta', 'sesenta', 'setenta', 'ochenta', 'noventa'];
    const centenas = ['cien', 'ciento', 'doscientos', 'trescientos', 'cuatrocientos', 'quinientos', 'seiscientos', 'setecientos', 'ochocientos', 'novecientos'];

    if (numero < 10) {
        return unidades[numero];
    } else if (numero < 20) {
        return decenas[numero - 10];
    } else if (numero < 100) {
        const u = numero % 10;
        const d = Math.floor(numero / 10);
        if (u === 0) {
            return decenas2[d];
        } else {
            return decenas2[d] + ' y ' + unidades[u];
        }
    } else if (numero < 1000) {
        const c = Math.floor(numero / 100);
        const resto = numero % 100;
        if (resto === 0) {
            return centenas[c];
        } else {
            return centenas[c] + ' ' + this.convertirNumeroALetrasEntero(resto);
        }
    } else {
        return 'Número fuera de rango';
    }
  }

  subscribeToFormChanges(): void {
    this.form.get('transaction_amount').valueChanges.subscribe(() => {
      this.calculateTotalAmount();
    });

    this.form.get('convertion_rate').valueChanges.subscribe(() => {
      this.calculateTotalAmount();
    });

    this.form.get('bank_account_id').valueChanges.subscribe(() => {
      this.calculateTotalAmount();
      const currencyId = this.project?.currency_id;
      const selectedBank = this.bankAccounts.find((el: any)=> el.id ===this.form.get('bank_account_id').value);
      const selectedCurrencyId = selectedBank?.currency?.id;
      this.showRateInput = selectedCurrencyId !== currencyId;
    });
  }


  calculateTotalAmount(): void {
    const receivedAmount : number = this.form.get('transaction_amount').value;
    const rate: number = this.form.get('convertion_rate').value;
    const bank = this.bankAccounts.find((el)=> el.id === this.form.get('bank_account_id').value);

    let totalAmount: number = 0;

    if (this.showRateInput && rate) {
      if (this.project?.currency_id === 1) {
        totalAmount = receivedAmount / rate;
      } else {
        totalAmount = receivedAmount * rate;
      }
    } else {
      totalAmount = receivedAmount;
    }

    // Redondear el monto total a dos decimales
    totalAmount = parseFloat(totalAmount.toFixed(2));

    this.form.get('amount').setValue(totalAmount);
  }

  async setBank(e: any): Promise<void> {
    this.calculateTotalAmount();
    const bank = await this.bankAccounts.find((el: any)=> el.id === e.value);
    if (!bank) return;
    const currencyId = this.project?.currency_id;
    if (bank?.currency?.id !== currencyId) {
      this.showRateInput = true;
      this.form.get('convertion_rate').setValidators([Validators.required]);
    } else {
      this.showRateInput = false;
      this.form.get('convertion_rate').clearValidators();
    }
  }

}
