<p-toast position="top-right" key="tr" [baseZIndex]="20000"></p-toast>
<app-spinner *ngIf="loading"></app-spinner>
<p-card *ngIf="!loading" styleClass="px-3">
  <p-steps
    [model]="steps"
    [(activeIndex)]="activeIndex"
    [readonly]="true"
  ></p-steps>
  <p-toolbar>
    <div class="p-toolbar-group-left">
      <p class="lead" style="font-size: 20px">Negocio: {{ business?.name }}</p>
    </div>

    <div class="p-toolbar-group-right">
      <!-- <p-chip [label]="business?.business_status?.name" styleClass="mr-2"></p-chip> -->
      <p-splitButton
        *ngIf="items.length > 0"
        [disabled]="business?.business_status?.name === 'Perdido'"
        label="Acciones"
        [model]="items"
        styleClass="p-button-primary mr-2 mb-2"
      ></p-splitButton>
    </div>
  </p-toolbar>

  <!-- <div class="card flex justify-content-center"> -->
  <p-dialog
    header="Usuario Actual"
    [(visible)]="showModal"
    [modal]="true"
    [style]="{ width: '40vw', height: '70vh' }"
    [draggable]="false"
    [resizable]="false"
  >
    <div class="row">
      <p-divider align="center">
        <p-chip
          [label]="business?.user?.name"
          icon="pi pi-user"
          styleClass="mr-2 custom-chip"
        ></p-chip>
      </p-divider>
      <p-divider align="left">
        <div class="inline-flex align-items-center">
          <i class="pi pi-arrow-right-arrow-left mr-2"></i>
          <b>Asignar a:</b>
        </div>
      </p-divider>
      <div class="col-md-12 col-lg-12 mb-5">
        <!-- <p-dropdown [options]="users" [(ngModel)]="selectedUser" optionLabel="names" optionValue="id" [filter]="true" filterBy="names" styleClass="w-100" panelStyleClass="panel-style"></p-dropdown> -->
        <p-listbox
          [options]="users"
          [(ngModel)]="selectedUser"
          optionLabel="names"
          optionValue="id"
          [filter]="true"
          filterBy="names"
          styleClass="w-100"
          [listStyle]="{ 'max-height': '140px' }"
        ></p-listbox>
      </div>
    </div>
    <ng-template pTemplate="footer">
      <p-button
        icon="pi pi-check"
        (click)="asignNewUSer()"
        label="Guardar"
        styleClass="p-button-text"
      ></p-button>
    </ng-template>
  </p-dialog>
  <p-confirmDialog
    [style]="{ width: '30vw' }"
    acceptLabel="Continuar"
    rejectLabel="Cancelar"
    acceptIcon="pi pi-check-circle"
    rejectIcon="pi pi-times"
    rejectButtonStyleClass="p-button-text p-button-secondary"
    acceptButtonStyleClass="p-button-text p-button-primary"
    [baseZIndex]="10000"
    key="businessDialog"
  ></p-confirmDialog>
  <!-- </div> -->

  <p-tabView styleClass="tabview-custom" [(activeIndex)]="tabIndex">
    <p-tabPanel header="Información General">
      <ng-template pTemplate="header">
        <i class="pi pi-file mr-1"></i>
        <span>Información General</span>
      </ng-template>

      <div class="container py-4">
        <div class="row">
          <div class="col-12 col-lg-4">
            <div class="container">
              <app-general-info [business]="business"></app-general-info>
            </div>
          </div>

          <div class="col-12 col-lg-8 mt-[3rem] lg:mt-0 ">
            <div class="container">
                <app-activity-chart [moduleId]="moduleId" [objectId]="businessId" (isSuccessMessage)="showSuccessActivity($event)"></app-activity-chart>
            </div>
          </div>
        </div>
      </div>

    </p-tabPanel>
    <p-tabPanel header="Cobros" *ngIf="collections.length > 0">
      <ng-template pTemplate="header">
        <i class="pi pi-credit-card mr-1"></i>
        <span>Cobros</span>
      </ng-template>
      <app-list-by-business
        [collections]="collections"
        [business]="business"
        [canEdit]="canEditCollection"
        [canCreate]="canCreateCollection"
        [user]="currentUser?.user"
        (complete)="getBusiness()"
      ></app-list-by-business>
    </p-tabPanel>
    <p-tabPanel header="Cobros" *ngIf="paymentPermissions?.read">
      <ng-template pTemplate="header">
        <i class="pi pi-book mr-1"></i>
        <span>Plan de pagos</span>
      </ng-template>
      <app-payment-plan
        [business]="business"
        [paymentsPlan]="paymentsPlan"
        [permissions]="paymentPermissions"
        (status)="getBusiness($event)"
        (isSaveButtonAble)="setPaymentButtonStatus($event)"
      ></app-payment-plan>
    </p-tabPanel>
    <p-tabPanel header="Documents" *ngIf="[2, 3].includes(business?.business_status?.id)">
      <ng-template pTemplate="header">
        <i class="pi pi-file mr-1"></i>
        <span>Documentos</span>
      </ng-template>
      <app-list-by-documents [documents]="business?.documents" [business]="business" [user]="currentUser?.user" (updateListDocuments)="documentList($event)" (failed)="showFailedMessage($event)" (isSuccess)="documentComplete($event)"></app-list-by-documents>
    </p-tabPanel>
    <p-tabPanel header="Traspasos">
      <ng-template pTemplate="header">
        <i class="pi pi-sort-alt mr-1"></i>
        <span>Traspasos</span>
      </ng-template>
      <app-list-by-transfers [collections]="transfers" [business]="business" [user]="currentUser?.user" (updateListTransfers)="transferList($event)"></app-list-by-transfers>
    </p-tabPanel>
  </p-tabView>
  <div class="form-footer">
    <button
      *ngIf="(business?.business_status?.id === 1 && tabIndex === 1) || (business?.business_status?.id === 2 && tabIndex === 2)"
      [disabled]="savePaymentButton" pButton class="p-button-raised p-button-sm p-button-secondary mr-2"
      (click)="updatePlan()">
      Guardar cambios
    </button>
    <button [routerLink]="['/bussines']" pButton class="p-button-raised p-button-sm return-button">
      Regresar
    </button>
  </div>

  <ng-container *ngIf="loadingAction">
    <app-spinner [overlay]="true" ></app-spinner>
  </ng-container>

  <app-create-plan *ngIf="business" [visible]="showPaymentForm" [business]="business"
    (showPlanForm)="showPaymentForm = $event" (isSuccess)="getBusiness($event)"></app-create-plan>

  <app-create-transfer-unit *ngIf="business" [visible]="showTransferUnit" [business]="business"
    (emitVisible)="showTransferUnit = $event" (isSuccess)="getBusiness($event)" [projects]="projects" ></app-create-transfer-unit>

  <app-create-transfer-client *ngIf="business" [visible]="showTransferClient" [business]="business"
    (emitVisible)="showTransferClient = $event" (isSuccess)="getBusiness($event)" [clients]="clients" (updateClients)="tranferClient()"></app-create-transfer-client>

  <app-create-charge [visible]="showCollectionModal" [data]="collectionData" (showModal)="showCollectionModal = $event" (showErrorMsg)="showCollectionMsg($event)" (showSuccessMsg)="showCollectionMsg($event)"></app-create-charge>

</p-card>
