import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BusinessService {

public apiUrl = environment.apiUrl;

constructor(
  private http: HttpClient
) { }

get(params: HttpParams|null = null): Observable<any> {
  let url = `${this.apiUrl}business/list`;

  return this.http.get(url,{
    params: params ?? null
  });
}

status(): Observable<any> {
  let url = `${this.apiUrl}business/status/list?`;
  return this.http.get(url);
}

types(): Observable<any> {
  let url = `${this.apiUrl}property/types/list?`;
  return this.http.get(url);
}

create(body:FormData): Observable<any> {
  let url = `${this.apiUrl}business/create`;
  return this.http.post(url, body);
}

transferClient(body:FormData): Observable<any> {
  let url = `${this.apiUrl}business/transfer/client`;
  return this.http.post(url, body);
}
transferProperty(body:FormData): Observable<any> {
  console.log(body, 'el body');
  let url = `${this.apiUrl}business/transfer/property`;
  return this.http.post(url, body);
}

transferList(params: HttpParams|null = null): Observable<any> {
  let url = `${this.apiUrl}business/transfer/list`;

  return this.http.get(url,{
    params: params ?? null
  });
}

update(body:FormData, businessId:number): Observable<any> {
  let url = `${this.apiUrl}business/update/${businessId}`;
  return this.http.post(url, body);
}

updateStatusToLost(businessId:number): Observable<any> {
  let url = `${this.apiUrl}business/update-status/lost/${businessId}`;
  return this.http.put(url,{});
}

detail(businessId:number): Observable<any>{
  const url = `${this.apiUrl}business/${businessId}`
  return this.http.get(url);
}

delete(propertyId:number): Observable<any> {
  let url = `${this.apiUrl}property/delete/${propertyId}`;
  return this.http.delete(url);
}

setNewUser(businessId: number, userId: number): Observable<any> {
  let url = `${this.apiUrl}business/set-user/${businessId}`;
  return this.http.post(url, {user_id: userId});
}

generatePdf(projectId, statusId): Observable<any>{
  const url = `${this.apiUrl}business/generate-business-report/${statusId}/${projectId}`
  return this.http.get(url, { responseType: 'blob' });
}

listDocuments(businessId:number): Observable<any>{
  const url = `${this.apiUrl}business/documents/${businessId}`
  return this.http.get(url);
}

uploadDocument(body:FormData): Observable<any>{
  const url = `${this.apiUrl}business/documents`
  return this.http.post(url, body);
}

deleteDocument(businessId:number): Observable<any>{
  const url = `${this.apiUrl}business/documents/${businessId}`
  return this.http.delete(url);
}

getTypesDocuments(): Observable<any>{
  const url = `${this.apiUrl}business-type-documents`
  return this.http.get(url);
}

createTypeDocument(body: any): Observable<any>{
  const url = `${this.apiUrl}business-type-documents`
  return this.http.post(url, body);
}

updateTypeDocument(body: any, typeId: number): Observable<any>{
  const url = `${this.apiUrl}business-type-documents/${typeId}`
  return this.http.put(url, body);
}

deleteTypeDocument(typeId: number): Observable<any>{
  const url = `${this.apiUrl}business-type-documents/${typeId}`
  return this.http.delete(url);
}

}
